/* eslint-disable default-case */
import moment from "moment";
export function generateDefaultPodProduct(data) {
  const result = [
    ...processPremiumPlan(data.plans),
    ...processAddons(data.addons),
    ...processCollectionCharges(data.charges.collection_charge),
    ...processCollectionCongestionCharges(
      data.charges.collection_congestion_charge
    ),
    // ...processReturnCharges(data.charges.return_charge),
    // ...processReturnCongestionCharges(data.charges.return_congestion_charge),
  ];

  return result;
}

// Process Premium Plan
const processPremiumPlan = (plans) => {
  console.log("tak-90", process.env);

  const premiumPlan = plans.find(
    (plan) => plan.stripe_product_id === process.env.REACT_APP_PRODUCT_PREMIUM
  );
  if (!premiumPlan?.product_prices.length) return [];
  const [firstPrice] = premiumPlan.product_prices;
  return [
    {
      id: firstPrice.id,
      name: premiumPlan.name,
      type: premiumPlan.type,
      quantity: firstPrice.tier_start_unit || 1,
      price_id: firstPrice.stripe_product_id,
      cost: firstPrice.unit_amount,
      total: firstPrice.unit_amount * (firstPrice.tier_start_unit || 1),
      product_prices: premiumPlan.product_prices,
    },
  ];
};

// Process Addons
const processAddons = (addons) => {
  return addons.flatMap((addon) =>
    addon.product_prices.map((price) => ({
      id: price.id,
      name: addon.name,
      type: addon.type,
      quantity: 1,
      cover: 4000,
      price_id: price.stripe_product_id,
      cost: price.unit_amount,
      total: price.unit_amount * 4,
    }))
  );
};

// Process Collection Charges
const processCollectionCharges = (collectionCharges) => {
  const collection = collectionCharges.find(
    (charge) =>
      charge.stripe_product_id ===
      process.env.REACT_APP_PRODUCT_COLLECTION_SERVICE
  );
  if (!collection?.product_prices.length) return [];
  const [firstPrice] = collection.product_prices;
  return [
    {
      id: firstPrice.id,
      name: collection.name,
      type: collection.type,
      quantity: firstPrice.tier_start_unit || 1,
      price_id: firstPrice.stripe_product_id,
      cost: firstPrice.unit_amount,
      total: firstPrice.unit_amount * (firstPrice.tier_start_unit || 1),
      product_prices: collection.product_prices,
    },
  ];
};

// Process Collection Congestion Charges
const processCollectionCongestionCharges = (collectionCongestionCharges) => {
  return collectionCongestionCharges.flatMap((charge) =>
    charge.product_prices.map((price) => ({
      id: price.id,
      name: charge.name,
      type: charge.type,
      quantity: 1,
      price_id: price.stripe_product_id,
      cost: price.unit_amount,
      total: price.unit_amount,
    }))
  );
};

// Process Return Charges
const processReturnCharges = (returnCharges) => {
  return returnCharges.flatMap((charge) =>
    charge.product_prices.map((price) => ({
      id: price.id,
      name: charge.name,
      type: charge.type,
      quantity: 1,
      price_id: price.stripe_product_id,
      cost: price.unit_amount,
      total: price.unit_amount,
    }))
  );
};

// Process Return Congestion Charges
const processReturnCongestionCharges = (returnCongestionCharges) => {
  return returnCongestionCharges.flatMap((charge) =>
    charge.product_prices.map((price) => ({
      id: price.id,
      name: charge.name,
      type: charge.type,
      quantity: 1,
      price_id: price.stripe_product_id,
      cost: price.unit_amount,
      total: price.unit_amount,
    }))
  );
};

// Example usage

export function transformDataToAddProduct(charge) {
  // Initialize a default object
  console.log("charge-90", charge);

  const transformedObject = {
    id: null,
    name: charge.name,
    type: charge.type,
    quantity: 1,
    price_id: charge.stripe_product_id,
    cost: charge.price || null,
    total: charge.price || null,
    product_prices: null,
  };

  // Check if charge.product_prices exists and has at least one item
  if (charge.product_prices && charge.product_prices.length > 0) {
    // Process only the first item (index 0)
    const price = charge.product_prices[0];
    transformedObject.id = price.id;
    transformedObject.price_id = price.stripe_product_id;
    transformedObject.cost = price.unit_amount;
    transformedObject.total = price.unit_amount;
    transformedObject.product_prices = charge.product_prices;
  }

  return transformedObject;
}

export const isItemExists = (array, item) => {
  // Adjust the identifier based on your actual use case (e.g., 'id')

  return array.some((existingItem) => existingItem.price_id === item.price_id);
};

export function multiplyCostByNumber(data, number, state) {
  return data.map((item) => {
    if (item.type === "plan") {
      return {
        ...item,
        // cost: (parseFloat(item.cost) * number).toFixed(2),
        total:
          item?.product_prices && item?.product_prices?.length == 1
            ? (parseFloat(item.cost) * number * item?.quantity).toFixed(2)
            : (parseFloat(item.cost) * number).toFixed(2),
      };
    }
    if (item.type === "addon") {
      if (
        item?.price_id === process.env.REACT_APP_PRODUCT_POD_PROTECT ||
        item?.price_id === process.env.REACT_APP_PRODUCT_STORE_PROTECT ||
        item?.price_id === "Storeprotect"
      ) {
        return {
          ...item,
          // cost: (parseFloat(item.cost) * number).toFixed(2),
          total: ((parseFloat(item.cost) * number * item.cover) / 1000).toFixed(
            2
          ),
        };
      } else {
        return {
          ...item,
          // cost: (parseFloat(item.cost) * number).toFixed(2),
          total: (parseFloat(item.cost) * number).toFixed(2),
        };
      }
    }

    return item;
  });
}

export function calculateDefaultDiscount(data, discount, value) {
  let discountAmount = 0;
  // Find the discount that applies to plans
  const planDiscount = discount.find((d) => d.object === "coupon");

  // If no valid plan discount found, return 0
  if (!planDiscount) {
    return discountAmount;
  }

  // Calculate discount for each plan in data
  data.forEach((item) => {
    if (item.type === "plan") {
      const itemDiscount =
        (parseFloat(item.total) * planDiscount.percent_off) / 100;
      discountAmount += itemDiscount;
    }
  });

  return discountAmount;
}

export function isWeekend(dateString) {
  const date = new Date(dateString);
  const day = date.getDay(); // 0 (Sunday) to 6 (Saturday)
  const publicHolidays = [
    // 2024 Holidays
    "2024-01-01", // New Year's Day
    "2024-03-29", // Good Friday
    "2024-04-01", // Easter Monday
    "2024-05-06", // Early May Bank Holiday
    "2024-05-27", // Spring Bank Holiday
    "2024-08-26", // Summer Bank Holiday
    "2024-12-25", // Christmas Day
    "2024-12-26", // Boxing Day,
    // 2025 Holidays
    "2025-01-01", // New Year's Day
    "2025-04-18", // Good Friday
    "2025-04-21", // Easter Monday
    "2025-05-05", // Early May Bank Holiday
    "2025-05-26", // Spring Bank Holiday
    "2025-08-25", // Summer Bank Holiday
    "2025-12-25", // Christmas Day
    "2025-12-26", // Boxing Day

    // 2026 Holidays
    "2026-01-01", // New Year's Day
    "2026-04-03", // Good Friday
    "2026-04-06", // Easter Monday
    "2026-05-04", // Early May Bank Holiday
    "2026-05-25", // Spring Bank Holiday
    "2026-08-31", // Summer Bank Holiday
    "2026-12-25", // Christmas Day
    "2026-12-28", // Boxing Day (Substitute)

    // 2027 Holidays
    "2027-01-01", // New Year's Day
    "2027-03-26", // Good Friday
    "2027-03-29", // Easter Monday
    "2027-05-03", // Early May Bank Holiday
    "2027-05-31", // Spring Bank Holiday
    "2027-08-30", // Summer Bank Holiday
    "2027-12-25", // Christmas Day
    "2027-12-27", // Boxing Day (Substitute)

    // 2028 Holidays
    "2028-01-01", // New Year's Day
    "2028-04-14", // Good Friday
    "2028-04-17", // Easter Monday
    "2028-05-01", // Early May Bank Holiday
    "2028-05-29", // Spring Bank Holiday
    "2028-08-28", // Summer Bank Holiday
    "2028-12-25", // Christmas Day
    "2028-12-26", // Boxing Day

    // 2029 Holidays
    "2029-01-01", // New Year's Day
    "2029-03-30", // Good Friday
    "2029-04-02", // Easter Monday
    "2029-05-07", // Early May Bank Holiday
    "2029-05-28", // Spring Bank Holiday
    "2029-08-27", // Summer Bank Holiday
    "2029-12-25", // Christmas Day
    "2029-12-26", // Boxing Day

    // 2030 Holidays
    "2030-01-01", // New Year's Day
    "2030-04-19", // Good Friday
    "2030-04-22", // Easter Monday
    "2030-05-06", // Early May Bank Holiday
    "2030-05-27", // Spring Bank Holiday
    "2030-08-26", // Summer Bank Holiday
    "2030-12-25", // Christmas Day
    "2030-12-26", // Boxing Day
  ];

  // Check if the date is a public holiday
  console.log("datedate", date, dateString);

  const formattedDate = date.toISOString().split("T")[0]; // Format date to 'YYYY-MM-DD'
  if (publicHolidays.includes(formattedDate)) {
    return "Public";
  }
  if (day === 0 || day === 6) {
    return "Weekend";
  } else {
    return "Weekday";
  }
}

export function updateChargesForDayType(charges, dayType, products) {
  console.log("dayType", dayType);

  const collectionServiceCharge = charges.collection_charge.find(
    (charge) =>
      charge.stripe_product_id ===
      process.env.REACT_APP_PRODUCT_COLLECTION_SERVICE
  );
  const weekendCharge = charges.collection_charge.find(
    (charge) =>
      charge.stripe_product_id === process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE
  );
  const pubHoliday = charges.collection_charge.find(
    (charge) =>
      charge.stripe_product_id === process.env.REACT_APP_PRODUCT_COL_PUB_HOLIDAY
  );
  console.log("pubHoliday", pubHoliday, charges.collection_charge);

  const planQty = products.find((e) => e.type === "plan")?.quantity;
  const additionalTiers = planQty - 6;
  if (dayType === "Weekend") {
    // Remove "Collection Service" from products
    products = products.filter(
      (product) =>
        product.price_id !== process.env.REACT_APP_PRODUCT_COLLECTION_SERVICE &&
        product.price_id !== process.env.REACT_APP_PRODUCT_COL_PUB_HOLIDAY
    );

    // Add "Weekend Charge" if not already present
    if (
      !products.some(
        (product) =>
          product.price_id === process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE
      )
    ) {
      if (weekendCharge) {
        products.push({
          id: weekendCharge.id,
          name: weekendCharge.name,
          type: weekendCharge.type,
          quantity: planQty,
          product_prices: weekendCharge.product_prices,
          price_id: weekendCharge.stripe_product_id,
          cost:
            weekendCharge.product_prices.length > 5 && planQty > 6
              ? Number(weekendCharge.product_prices[5].unit_amount) +
                Number(weekendCharge.product_prices[6].unit_amount) *
                  Number(additionalTiers)
              : weekendCharge.product_prices[planQty - 1].unit_amount ||
                weekendCharge.product_prices[0].unit_amount,
          total:
            weekendCharge.product_prices.length > 5 && planQty > 6
              ? Number(weekendCharge.product_prices[5].unit_amount) +
                Number(weekendCharge.product_prices[6].unit_amount) *
                  Number(additionalTiers)
              : weekendCharge.product_prices[planQty - 1].unit_amount ||
                weekendCharge.product_prices[0].unit_amount,
        });
      }
    }
  } else if (dayType === "Weekday") {
    // Remove "Weekend Charge" from products
    products = products.filter(
      (product) =>
        product.price_id !== process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE &&
        product.price_id !== process.env.REACT_APP_PRODUCT_COL_PUB_HOLIDAY
    );

    // Add "Collection Service" if not already present
    if (
      !products.some(
        (product) =>
          product.price_id === process.env.REACT_APP_PRODUCT_COLLECTION_SERVICE
      )
    ) {
      if (collectionServiceCharge) {
        products.push({
          id: collectionServiceCharge.id,
          name: collectionServiceCharge.name,
          type: collectionServiceCharge.type,
          quantity: planQty,
          product_prices: collectionServiceCharge.product_prices,
          price_id: collectionServiceCharge.stripe_product_id,
          cost:
            collectionServiceCharge.product_prices.length > 5 && planQty > 6
              ? Number(collectionServiceCharge.product_prices[5].unit_amount) +
                Number(collectionServiceCharge.product_prices[6].unit_amount) *
                  Number(additionalTiers)
              : collectionServiceCharge.product_prices[planQty - 1]
                  .unit_amount ||
                collectionServiceCharge.product_prices[0].unit_amount,
          total:
            collectionServiceCharge.product_prices.length > 5
              ? Number(collectionServiceCharge.product_prices[5].unit_amount) +
                Number(collectionServiceCharge.product_prices[6].unit_amount) *
                  Number(additionalTiers)
              : collectionServiceCharge.product_prices[planQty - 1]
                  .unit_amount ||
                collectionServiceCharge.product_prices[0].unit_amount,
        });
      }
    }
  } else if (dayType === "Public") {
    // Remove "Weekend Charge" from products
    products = products.filter(
      (product) =>
        product.price_id !== process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE &&
        product.price_id !== process.env.REACT_APP_PRODUCT_COLLECTION_SERVICE
    );
    console.log("products", products);

    // Add "Collection Service" if not already present
    if (
      !products.some(
        (product) =>
          product.price_id === process.env.REACT_APP_PRODUCT_COL_PUB_HOLIDAY
      )
    ) {
      if (pubHoliday) {
        products.push({
          id: pubHoliday.id,
          name: pubHoliday.name,
          type: pubHoliday.type,
          quantity: planQty,
          product_prices: pubHoliday.product_prices,
          price_id: pubHoliday.stripe_product_id,
          cost:
            pubHoliday.product_prices.length > 5 && planQty > 6
              ? Number(pubHoliday.product_prices[5].unit_amount) +
                Number(pubHoliday.product_prices[6].unit_amount) *
                  Number(additionalTiers)
              : pubHoliday.product_prices[planQty - 1].unit_amount ||
                pubHoliday.product_prices[0].unit_amount,
          total:
            pubHoliday.product_prices.length > 5
              ? Number(pubHoliday.product_prices[5].unit_amount) +
                Number(pubHoliday.product_prices[6].unit_amount) *
                  Number(additionalTiers)
              : pubHoliday.product_prices[planQty - 1].unit_amount ||
                pubHoliday.product_prices[0].unit_amount,
        });
      }
    }
  }
  return products;
}

export function updateReturnChargesForDayType(charges, dayType, products) {
  const collectionServiceCharge = charges.return_charge.find(
    (charge) =>
      charge.stripe_product_id === process.env.REACT_APP_PRODUCT_RET_SER
  );
  const weekendCharge = charges.return_charge.find(
    (charge) =>
      charge.stripe_product_id ===
      process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE
  );
  const pubCharge = charges.return_charge.find(
    (charge) =>
      charge.stripe_product_id === process.env.REACT_APP_PRODUCT_RET_PUB_HOLIDAY
  );
  if (dayType === "Weekend") {
    // Remove process.env.REACT_APP_PRODUCT_RET_SER from products
    products = products.filter(
      (product) =>
        product.price_id !== process.env.REACT_APP_PRODUCT_RET_SER &&
        product.price_id !== process.env.REACT_APP_PRODUCT_RET_PUB_HOLIDAY
    );

    // Add process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE if not already present
    if (
      !products.some(
        (product) =>
          product.price_id === process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE
      )
    ) {
      if (weekendCharge) {
        products.push({
          id: weekendCharge.id,
          name: weekendCharge.name,
          type: weekendCharge.type,
          quantity: 1,
          product_prices: weekendCharge.product_prices,

          price_id: weekendCharge.stripe_product_id,
          cost: weekendCharge.product_prices[0].unit_amount,
          total: weekendCharge.product_prices[0].unit_amount,
        });
      }
    }
  } else if (dayType === "Weekday") {
    // Remove process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE from products
    products = products.filter(
      (product) =>
        product.price_id !== process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE &&
        product.price_id !== process.env.REACT_APP_PRODUCT_RET_PUB_HOLIDAY
    );

    // Add process.env.REACT_APP_PRODUCT_RET_SER if not already present
    if (
      !products.some(
        (product) => product.price_id === process.env.REACT_APP_PRODUCT_RET_SER
      )
    ) {
      if (collectionServiceCharge) {
        products.push({
          id: collectionServiceCharge.id,
          name: collectionServiceCharge.name,
          type: collectionServiceCharge.type,
          quantity: 1,
          product_prices: collectionServiceCharge.product_prices,
          price_id: collectionServiceCharge.stripe_product_id,
          cost: collectionServiceCharge.product_prices[0].unit_amount,
          total: collectionServiceCharge.product_prices[0].unit_amount,
        });
      }
    }
  } else if (dayType === "Public") {
    // Remove process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE from products
    products = products.filter(
      (product) =>
        product.price_id !== process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE &&
        product.price_id !== process.env.REACT_APP_PRODUCT_RET_SER
    );

    // Add process.env.REACT_APP_PRODUCT_RET_SER if not already present
    if (
      !products.some(
        (product) =>
          product.price_id === process.env.REACT_APP_PRODUCT_RET_PUB_HOLIDAY
      )
    ) {
      if (pubCharge) {
        products.push({
          id: pubCharge.id,
          name: pubCharge.name,
          type: pubCharge.type,
          quantity: 1,
          product_prices: pubCharge.product_prices,
          price_id: pubCharge.stripe_product_id,
          cost: pubCharge.product_prices[0].unit_amount,
          total: pubCharge.product_prices[0].unit_amount,
        });
      }
    }
  }

  return products;
}

export const newOrderPaylod = (
  paymentId,
  state,
  orderSummaryListStripe,
  cardDetail,
  paymentIntentCus,
  formik
) => {
  function getProductNameByPriceId(data) {
    for (let item of data) {
      switch (item.price_id) {
        case process.env.REACT_APP_PRODUCT_PREMIUM:
          return "Premium";
        case process.env.REACT_APP_PRODUCT_ECONOMY:
          return "Economy";
        case process.env.REACT_APP_PRODUCT_FIRST_CLASS:
          return "First Class";
      }
    }
    return "Unknown Product";
  }

  // Usage
  const productNames = getProductNameByPriceId(state.product_array);
  // const productNames = getProductNameByPriceId(state.product_array);
  function formatCouponData(coupons) {
    return coupons.map((coupon) => {
      return { coupon: coupon.id };
    });
  }
  function formateManualData(coupons) {
    return coupons.map((coupon) => {
      return { coupon: coupon.id };
    });
  }
  function filterPackagingMaterials(data, product) {
    return data
      .filter((item) => item.type === "packaging_material")
      .map((item) => {
        return product.find((prod) => prod.price_id === item.stripe_product_id);
      })
      .filter(Boolean); // Remove undefined values in case there are no matches
  }
  const qty =
    state?.product_array.find((e) => e.type === "plan")?.quantity || 0;
  const filteredAvailData = state?.avail ? state?.avail.slice(0, +qty) : [];
  // [
  //     [
  //         'coupon' => 'coupon_1',
  //     ],
  //     [
  //         'coupon' => 'coupon_2',
  //     ],
  // ]
  console.log("products", state);

  const tenPercent =
    !state.collectionRetrun?.return_start_time_slot &&
    state.collectionRetrun?.collection_date &&
    (state?.containerTypeSelect != "pod" ||
      (state?.containerTypeSelect == "pod" &&
        state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID &&
        state?.product_array?.some(
          (e) =>
            e.type === "plan" &&
            e?.price_id !== process.env?.REACT_APP_PRODUCT_ECONOMY
        ))) &&
    new Date(state.collectionRetrun?.collection_date) >
      new Date(Date.now() + 48 * 60 * 60 * 1000);

  const data = {
    id:
      cardDetail && cardDetail.length && cardDetail[0].id
        ? cardDetail[0].id
        : "",
    amount: state?.variantProductTotal?.total?.toFixed(2) || "",
    address:
      cardDetail && cardDetail.length && cardDetail[0].address
        ? cardDetail[0].address
        : "",
    adhoc_charge_price: "",
    adhoc_charge_quantity: "",
    agent_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact?.agent?.id
        ? cardDetail[0].contact?.agent?.id
        : "",
    apply_manual_discounts: "",
    bp_id: "POD",
    city:
      cardDetail && cardDetail.length && cardDetail[0].city
        ? cardDetail[0].city
        : "",
    collection_address_city:
      state.collectionRetrun?.collection_address_city || "",
    collection_address_line:
      state.collectionRetrun?.collection_address_line || "",
    collection_date: state.collectionRetrun?.collection_date || "",
    collection_postcode: state.collectionRetrun?.collection_postcode || "",
    collection_van_id: state.collectionRetrun?.collection_van_id || "",
    contact_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact?.id
        ? cardDetail[0].contact?.id
        : "",
    country: "GB",
    currency_code: "GBP",
    depot_id: state.collectionRetrun?.depot_id || "",
    // driver_note: "",
    email:
      cardDetail && cardDetail.length && cardDetail[0].email
        ? cardDetail[0].email
        : "",
    end_time_slot: state.collectionRetrun?.end_time_slot || "",
    first_name:
      cardDetail && cardDetail.length && cardDetail[0].first_name
        ? cardDetail[0].first_name
        : "",
    last_name:
      cardDetail && cardDetail.length && cardDetail[0].last_name
        ? cardDetail[0].last_name
        : "",
    franchise_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact?.franchise_id
        ? cardDetail[0].contact?.franchise_id
        : "",
    insurance_cover:
      state?.product_array.find(
        (e) =>
          e.price_id === process.env.REACT_APP_PRODUCT_POD_PROTECT ||
          e.price_id === process.env.REACT_APP_PRODUCT_STORE_PROTECT ||
          e.price_id === "Storeprotect"
      )?.cover || "",
    is_address_manual: "",
    is_billing_address: "",
    is_collection_address: "",
    is_manual: "",
    is_manual_discount: "",
    is_return_address: "",
    is_return_address_manual: "",
    is_student: state.is_student || 0,
    is_student_return: state.is_student_return || 0,
    packaging_materials: "",
    payment_type: formik.values.paymentType,
    period: state?.billingParam?.unit || "",
    period_unit: state?.billingParam?.period || "",
    phone:
      cardDetail && cardDetail.length && cardDetail[0].phone
        ? cardDetail[0].phone
        : "",
    pod_plan_type: productNames || "",
    products: [],
    is_return:
      state.collectionRetrun?.return_date &&
      state.collectionRetrun?.return_start_time_slot
        ? 1
        : "0",
    return_start_time_slot:
      state.collectionRetrun?.return_start_time_slot || "",
    is_same_time_return: state.collectionRetrun?.return_start_time_slot ? 1 : 0,
    return_end_time_slot: state.collectionRetrun?.return_end_time_slot || "",
    return_address_line: state.collectionRetrun?.return_address_line || "",
    return_address_city: state.collectionRetrun?.return_address_city || "",
    return_van_id: state.collectionRetrun?.return_van_id || "",
    return_date: state.collectionRetrun?.return_date || "",
    return_postcode: state.collectionRetrun?.return_postcode || "",
    ship_packaging_materials: state?.is_packaging_check ? 1 : 0,
    shipping_city: "",
    shipping_line_one: "",
    shipping_line_three: "",
    shipping_line_two: "",
    start_time_slot: state.collectionRetrun?.start_time_slot || "",
    state: "",
    storage_note: "",
    van_id: state.collectionRetrun?.van_id || "",
    default_payment_method: paymentId?.payment_method || "",
    planPodsQty:
      state?.product_array?.find((e) => e.type === "plan")?.quantity || 0,

    customer_id: paymentIntentCus?.data_two?.id || "",
    coupon_id:
      state?.default_coupon?.length > 0
        ? [
            ...formatCouponData(state?.default_coupon),
            ...formateManualData(state?.promoCoupon),
          ]
        : [],
    is_container: state?.containerTypeSelect === "container" ? 1 : 0,
    container_collection_date:
      state.collectionRetrun?.container_collection_date || "",
    container_return_date: state.collectionRetrun?.container_return_date || "",
    access_type:
      state?.product_array.find(
        (e) =>
          e.price_id == process.env.REACT_APP_PRODUCT_STANDARD_ACCESS ||
          e.price_id == process.env.REACT_APP_PRODUCT_EXTENDED_ACCESS ||
          e.price_id == process.env.REACT_APP_PRODUCT_24HR_ACCESS
      )?.price_id || "",
    container_size: state?.containerListSelect || "",
    franchise_container_id: filteredAvailData.map((e) => e.id) || "",
    site_id: state?.siteSelect?.id || "",
    deal_id: "",
    driver_note: state?.driverNote || "",
    total_discount:
      state?.variantProductTotal?.couponDiscount ||
      state?.variantProductTotal?.defaultDiscount
        ? Number(
            Number(state?.variantProductTotal?.couponDiscount) +
              Number(state?.variantProductTotal?.defaultDiscount || 0)
          ).toFixed(2)
        : "0.00",
    packagingMaterial: filterPackagingMaterials(
      orderSummaryListStripe?.allProducts,
      state?.product_array
    ),
  };

  const transformData = (data) => {
    return data.map((item) => {
      return {
        stripe_product_id: item.price_id,
        quantity: item.quantity,
        name: item.name,
        total: item.total,
        type: item.type,
        period: state?.billingParam?.unit || "",
        period_unit: state?.billingParam?.period || "",
        cover:
          item.price_id === process.env.REACT_APP_PRODUCT_POD_PROTECT
            ? item.cover
            : "",
      };
    });
  };

  const transformedData = transformData(state?.product_array);
  data.products = transformedData;

  if (tenPercent) {
    data.amount_10 = tenPercent
      ? Number(Number(state?.variantProductTotal?.total) * 0.1).toFixed(2)
      : "";
    data.amount_90 = tenPercent
      ? Number(Number(state?.variantProductTotal?.total) * 0.9).toFixed(2)
      : "";
    data.is_reservation = tenPercent ? 1 : 0;
  }
  return data;
};

//container
export function generateDefaultContainerProduct(data) {
  console.log(
    "processContainerCharges",
    processContainerAddons(data.addons),
    processContainerCharges(data.charges),
    data
  );

  const result = [
    ...processContainerPlan(data.plans),
    ...processContainerAddons(data.addons),
    ...processContainerCharges(data.charges),
  ];

  return result;
}

const processContainerPlan = (plans) => {
  const premiumPlan = plans.find((plan) => plan.type === "plan");
  if (!premiumPlan?.product_prices.length) return [];
  const [firstPrice] = premiumPlan.product_prices;
  return [
    {
      id: firstPrice.id,
      name: premiumPlan.name,
      type: premiumPlan.type,
      quantity: firstPrice.tier_start_unit || 1,
      price_id: firstPrice.stripe_product_id,
      cost: firstPrice.unit_amount,
      total: firstPrice.unit_amount * (firstPrice.tier_start_unit || 1),
      product_prices: premiumPlan.product_prices,
    },
  ];
};
const processContainerAddons = (addons) => {
  return addons.flatMap((addon) =>
    addon.product_prices.map((price) => ({
      id: price.id,
      name: addon.name,
      type: addon.type,
      quantity: 1,
      cover: 4000,
      price_id: price.stripe_product_id,
      cost: price.unit_amount,
      total:
        price.stripe_product_id == process.env.REACT_APP_PRODUCT_STORE_PROTECT
          ? price.unit_amount * 4
          : price.unit_amount,
    }))
  );
};
const processContainerCharges = (charges) => {
  // Flatten all charge categories into a single array
  const allCharges = Object.values(charges).flat();

  // Filter out the charges with specific product IDs
  const filteredCharges = allCharges.filter(
    (charge) =>
      charge.stripe_product_id !== process.env.REACT_APP_PRODUCT_24HR_ACCESS &&
      charge.stripe_product_id !== process.env.REACT_APP_PRODUCT_COL_SER_20FT &&
      charge.stripe_product_id !==
        process.env.REACT_APP_PRODUCT_EXTENDED_ACCESS &&
      charge.stripe_product_id !== process.env.REACT_APP_PRODUCT_RET_SER_20FT
  );

  if (filteredCharges.length === 0) return [];

  // Process each filtered charge
  return filteredCharges
    .map((charge) => {
      const [firstPrice] = charge.product_prices;
      if (!firstPrice) return null;
      return {
        id: firstPrice.id,
        name: charge.name,
        type: charge.type,
        quantity: firstPrice.tier_start_unit || 1,
        price_id: firstPrice.stripe_product_id,
        cost: parseFloat(firstPrice.unit_amount), // Convert to currency format
        total:
          parseFloat(firstPrice.unit_amount) *
          (firstPrice.tier_start_unit || 1),
        product_prices: charge.product_prices,
      };
    })
    .filter((item) => item !== null);
};

export function getWeekCountBetweenDates(startDate, endDate) {
  const start = moment(startDate).startOf("week");
  const end = moment(endDate).startOf("week");
  const weekCount = end.diff(start, "weeks") + 1; // Adding 1 to include the first week
  return weekCount;
}

// Example usage
